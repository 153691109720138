import React, {useState} from "react";
// API helper
import {apiHelper} from '../../../helper/apiHelper';
import {toast, ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import Container from "react-bootstrap/Container";
// scss
import styles from "./contact.module.scss";

const Contact = (props) => {

  const currLocale = props && props.currLocale;

  const [enteredEmail, setEnteredEmail] = useState('');
  const [emailIsValid, setEmailIsValid] = useState(false);
  const [emailInValidMsg, setEmailInValidMsg] = useState('');

  const emailChangeHandler = (event) => {
    setEnteredEmail(event.target.value);
  }

  const validateEmailHandler = () => {
    setEmailIsValid(enteredEmail.includes('@'));
    setEmailInValidMsg("Email should be either valid or not be blank.");
  }

  const submitContactHandler = (e) => {
    e.preventDefault();
    validateEmailHandler();
    if (emailIsValid) {
      if (enteredEmail === "") {
        setEmailInValidMsg("Please Enter Email");
      }
      else {
        setEmailInValidMsg('');
        const formData = new FormData();
        formData.append("name", 'User');
        formData.append("email", enteredEmail);
        formData.append("message", 'This message was submitted thru Contact Us section.');

        apiHelper('sendcontactus', 'POST', formData, null, currLocale)
          .then(res => {
            if (res.data.status) {
              toast.success(res.data.message);
              setEmailInValidMsg('');
              setEnteredEmail('');
            }
            else {
              setEmailInValidMsg(res.data.message);
            }
          })
          .catch(error => console.error(`Error: ${error}`));

      }
    }
  }
  
  return (
    <>
      <div className={styles.contact}>
        <Container>
          <div className={styles.contactBlock}>
            <div className={styles.left}>
              <div className={styles.title}>Let’s Talk!</div>
              <p className={styles.desc}>
                Enter your email, and we shall get back to you in an hour.
              </p>
            </div>
            <div className={styles.contactBg}>
              <div className={styles.right}>
                <form className={styles.inputGroup} method="post" onSubmit={submitContactHandler}>
                  <div className={styles.emailInput}>
                    <input
                      type="text"
                      placeholder="Your Email"
                      className={styles.formControl}
                      value={enteredEmail}
                      onChange={emailChangeHandler}
                      // onBlur={validateEmailHandler}
                    />
                    <p className="errorMsg" title={emailInValidMsg}>{emailInValidMsg}</p>
                  </div>
                  <button type="submit" className="btn secondary-btn">Contact Me</button>
                </form>
              </div>
            </div>
          </div>
          </Container>
      </div>
      <ToastContainer autoClose={2000} />
    </>
  );
}

export default Contact;